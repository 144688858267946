import React, { useEffect, useState } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import 'custom-event-polyfill'
import Header from './components/Header'
import LoginPage from './components/LoginPage'
import HomePage from './components/HomePage'
import SideNav from './components/SideNav'
import ListPage from './components/ListPage'
import AddPage from './components/AddPage'
import EditPage from './components/EditPage'
import SettingsPage from './components/SettingsPage'
import UserPage from './components/UserPage'
import ActivationsStats from './components/ActivationsStats'
import CouponStats from './components/CouponStats'
import CouponFind from './components/CouponFind'
import CouponAvailability from './components/CouponAvailability'
import ConsumerMigration from './components/ConsumerMigration'
import ConsumerFind from './components/ConsumerFind'
import ConsumerBalance from './components/ConsumerBalance'
import ConsumerRegistration from './components/ConsumerRegistration'
import ConsumerOTP from './components/ConsumerOTP'
import CreateBalance from './components/CreateBalance'
import JoinLookup from './components/JoinLookup'
import ImportPage from './components/ImportPage'
import SolutionZeroizer from './components/SolutionZeroizer'
import '../scss/style.scss'
import { useLocation, useParams } from 'react-router'

export default function MainView(props) {
  const [screen, setScreen] = useState({ width: window.innerWidth, height: window.innerHeight })
  const [user, setUser] = useState(props.user)
  const [loggedIn, setLoggedIn] = useState(props.loggedIn)
  const [nav, setNav] = useState(true)
  const [notifications, setNotifications] = useState([])

  const location = useLocation()
  const navigate = useNavigate()
  const urlParams = useParams()

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    if (screen.width < 1020) {
      setNav(false)
    }
    window.addEventListener("NOTIFY", (data) => { setTimeout(() => { addNotify(data) }, 200) })
    // eslint-disable-next-line
  }, [])

  const checkUser = () => {
      let data  = {}
      fetch(props.API+"/auth/describe", {
        method: "POST",
        headers: {
              "Content-Type": "application/json; charset=utf-8",
              "Authorization": "Bearer " + sessionStorage.getItem('token')
          },
        body: JSON.stringify(data)
      })
      .then((response) => {
          return response.json()
      })
      .then((result) => {
        if (result.status.success === false) {
          logout()
        } else {
          setUser(result.data.helpdesk)
        }
      }, (error) => {
        console.log(error)
      })
  }

  const addNotify = (e) => {
    let stamp = new Date()
    stamp = stamp.getTime()
    let temp = [...notifications]
    console.log(temp)
    let item = { type: e.detail.type, content: e.detail.content, stamp: stamp }
    temp.push(item)
    setNotifications(temp)
    setTimeout(() => {
      closeNotify(stamp)
    }, 12000)
  }

  const closeNotify = (stamp) => {
    let index = 999
    let temp = [...notifications]
    for (var i = 0; i < notifications.length; i++) {
      if(notifications[i].stamp === stamp) {
        index = i
      }
    }
    temp.splice(index, 1);
    setNotifications(temp)
  }

  const scrollTop = () => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  useEffect(() => {
    scrollTop()
  }, [location.pathname])

  const handleResize = (e) => {
    setScreen({ width: window.innerWidth, height: window.innerHeight })
    setNav(window.innerWidth < 1020 ? false : true)
  }

  const loginSuccess = (data) => {
    checkUser()
    setLoggedIn(true)
    let redirect = "/"
    if (location.hash.indexOf("#redirect=") !== -1) {
      redirect = location.hash.replace("#redirect=", "")
    }
    navigate(redirect)
  }

  const logout = () => {
    fetch(props.API+'/auth/logout', {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + sessionStorage.getItem('token')
      }
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      sessionStorage.removeItem('token')
      setLoggedIn(false)
      setUser({ name: "" })
      navigate("/login#redirect="+location.pathname)
    }, (error) => {
      console.log(error)
    })
  }

  const toggleNav = () => {
    setNav(!nav)
  }

  const hideNav = () => {
    if (screen.width < 1020) {
      setNav(false)
    }
  }

  return (
      <div className="app-inner">
        { loggedIn ? <Header data={ props.data }  location={ location } user={ user } loggedIn={ loggedIn } logout={ logout } toggleNav={ toggleNav } nav={ nav }/> : null }
        { loggedIn ? <SideNav data={ props.data } API={ props.API } user={ user } loggedIn={ loggedIn } hideNav={ hideNav } nav={ nav } /> : null }
        <div className={ nav ? "page-holder open" : "page-holder" }>
          <div key={ location.pathname } className="page">
            <Routes>
              <Route  path="/" exact element={<HomePage history={ navigate } data={ props.data } API={ props.API } screen={ screen } location={ location } user={ user } loggedIn={ loggedIn } sessionId = { props.sessionId } match={ urlParams }/>}/>
              <Route  path="/login" exact element={<LoginPage history={ navigate } data={ props.data } API={ props.API } screen={ screen } location={ location } loginSuccess={ loginSuccess } sessionId = { props.sessionId } match={ urlParams }/>}/>
              <Route  path="/import" exact element={<ImportPage history={ navigate } data={ props.data.import } API={ props.API } screen={ screen } location={ location } loginSuccess={ loginSuccess } sessionId = { props.sessionId } match={ urlParams }/>}/>
              <Route  path="/activations" exact element={<ActivationsStats history={ navigate } data={ props.data.activation } API={ props.API } screen={ screen } location={ location } loginSuccess={ loginSuccess } sessionId = { props.sessionId } match={ urlParams }/>}/>
              <Route  path="/award-stats" exact element={<CouponStats history={ navigate } data={ props.data.awardStats } API={ props.API } screen={ screen } location={ location } loginSuccess={ loginSuccess } sessionId = { props.sessionId } match={ urlParams }/>}/>
              <Route  path="/award-find" exact element={<CouponFind history={ navigate } data={ props.data.awardFind } API={ props.API } screen={ screen } location={ location } loginSuccess={ loginSuccess } sessionId = { props.sessionId } match={ urlParams }/>}/>
              <Route  path="/consumer-find" exact element={<ConsumerFind history={ navigate } data={ props.data.consumerFind} API={ props.API } screen={ screen } location={ location } loginSuccess={ loginSuccess } sessionId = { props.sessionId } match={ urlParams }/>}/>
              <Route  path="/consumer-balance" exact element={<ConsumerBalance history={ navigate } data={ props.data.consumerBalance} API={ props.API } screen={ screen } location={ location } loginSuccess={ loginSuccess } sessionId = { props.sessionId } match={ urlParams }/>}/>
              <Route  path="/consumer-history" exact element={<ConsumerBalance history={ navigate } data={ props.data.consumerHistory} API={ props.API } screen={ screen } location={ location } loginSuccess={ loginSuccess } sessionId = { props.sessionId } match={ urlParams }/>}/>
              <Route  path="/consumer-order" exact element={<ConsumerBalance history={ navigate } data={ props.data.consumerOrder} API={ props.API } screen={ screen } location={ location } loginSuccess={ loginSuccess } sessionId = { props.sessionId } match={ urlParams }/>}/>
              <Route  path="/consumer-purchase" exact element={<ConsumerBalance history={ navigate } data={ props.data.consumerPurchase} API={ props.API } screen={ screen } location={ location } loginSuccess={ loginSuccess } sessionId = { props.sessionId } match={ urlParams }/>}/>
              <Route  path="/consumer-award" exact element={<ConsumerBalance history={ navigate } data={ props.data.consumerAward} API={ props.API } screen={ screen } location={ location } loginSuccess={ loginSuccess } sessionId = { props.sessionId } match={ urlParams }/>}/>
              <Route  path="/consumer-registration" exact element={<ConsumerRegistration history={ navigate } data={ props.data.consumerRegistration} API={ props.API } screen={ screen } location={ location } loginSuccess={ loginSuccess } sessionId = { props.sessionId } match={ urlParams }/>}/>
              <Route  path="/create-balance" exact element={<CreateBalance history={ navigate } data={ props.data.createBalance} API={ props.API } screen={ screen } location={ location } loginSuccess={ loginSuccess } sessionId = { props.sessionId } match={ urlParams }/>}/>
              <Route key={ "settings-page" } path="/settings" exact element={<SettingsPage history={ navigate } data={ props.data.settings } API={ props.API } templates={ props.data.templates } screen={ screen } location={ location } sessionId = { props.sessionId } match={ urlParams }/>}/>
              <Route  path="/solution-zeroizer" exact element={<SolutionZeroizer history={ navigate } data={ props.data.solutionZeroizer} API={ props.API } screen={ screen } location={ location } loginSuccess={ loginSuccess } sessionId = { props.sessionId } match={ urlParams }/>}/>
              { props.data.pages.map((item, i) => {
                return (
                    <Route key={ i+"-list" } path={ item.route } exact element={<ListPage history={ navigate } data={ item } API={ props.API } user={ user } templates={ props.data.templates } screen={ screen } location={ location } sessionId = { props.sessionId } match={ urlParams }/>}/>
                  )
                })
              }
              { props.data.pages.map((item, i) => {
                return (
                    <Route key={ i+"-edit" } path={ item.editLink } exact element={<EditPage history={ navigate } data={ item } API={ props.API } user={ user } templates={ props.data.templates } screen={ screen } location={ location } sessionId = { props.sessionId } match={ urlParams }/>}/>
                  )
                })
              }
              { props.data.pages.map((item, i) => {
                return (
                    <Route key={ i+"-add" } path={ item.new } exact element={<AddPage history={ navigate } data={ item } API={ props.API } user={ user } templates={ props.data.templates } screen={ screen } location={ location } sessionId = { props.sessionId } match={ urlParams }/>}/>
                  )
                })
              }
              <Route key={ "user-page" } path={ props.data.user.route } exact element={<UserPage history={ navigate } data={ props.data.user } user={ user } API={ props.API } templates={ props.data.templates } screen={ screen } location={ location } sessionId = { props.sessionId } match={ urlParams }/>}/>
            </Routes>
          </div>
        </div>
        <div className="notifications">
          { notifications.map((item, i) => {
            return (
              <div key={ item.stamp } className="notification" >
                <div className={ "notification " + item.type } style={{ transform: "translateY("+(i*50)+"px)" }} onClick={ () => { closeNotify(item.stamp) } }>
                  <div className="close"></div>
                  <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
                </div>
              </div>
            ) }
          )}
        </div>
      </div>
  )
}